export default class Cell {
  /**
   * @param {Column} column
   * @param {string} value
   * @param {string} key
   */
  constructor({ column, value = "" }) {
    this.column = column;
    this.value = value;
  }

  get getColumn() {
    return this.column;
  }

  get getValue() {
    return _.get(this.value, this.column.id);
  }

  get getRaw() {
    return this.value;
  }

  get getByPathRaw() {
    return _.get(this.value, this.column.path);
  }
}
