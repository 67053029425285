export default class Column {
  /**
   * Represent a column of VuMyTable
   *
   * @constructor
   * @param {object} column - Column settings
   * @param {string} column.id
   * @param {string} column.name
   * @param {string} column.key
   * @param {string} column.path
   * @param {Object} column.styleCell
   * @param {Object} column.styleHeader
   * @param {Object} column.styleFooter
   * @param {Object} column.styleCell
   * @param {Object} column.styleHeader
   * @param {Object} column.styleFooter
   * @param {Object} column.classCell
   * @param {Object} column.classHeader
   * @param {Object} column.classFooter
   * @param {string|null} [column.key=null]
   */
  constructor({
    id,
    name,
    key = null,
    path = null,
    styleCell = {},
    styleHeader = {},
    styleFooter = {},
    classCell = {},
    classHeader = {},
    classFooter = {},
    useTranslate = true,
    meta = {},
  }) {
    this.id = id;
    this.name = name;
    this.key = key;
    this.path = path;
    this.styleCell = styleCell;
    this.styleHeader = styleHeader;
    this.styleFooter = styleFooter;
    this.classCell = classCell;
    this.classHeader = classHeader;
    this.classFooter = classFooter;
    this.useTranslate = useTranslate;
    this.meta = meta;
  }
}
