<template>
  <div class="row">
    <div class="col-md-12">
      <div class="data-load" :class="{ 'sk-loading': showSpinner, card: !stickyTable }">
        <sk-spinner v-if="showSpinner"></sk-spinner>
        <div :class="{ 'card-body': !stickyTable }">
          <div>
            <div class="row">
              <div class="col col-sm-8 col-md-8 col-lg-8">
                <h4 v-if="rows.length > 0">
                  {{ rows.length }} {{ $t("components.table.results") }}
                </h4>
              </div>
              <div class="col col-sm-4 col-md-4 col-lg-4 text-right" v-if="showRefreshButton">
                <a
                  href="#"
                  class="p-2 float-right btn-refresh"
                  @click.prevent="refreshButtonClicked"
                  ><i class="fas fa-sync-alt"></i
                ></a>
              </div>
            </div>
          </div>
          <div :class="{ 'table-responsive': !stickyTable }">
            <table :class="classTable">
              <thead>
                <tr>
                  <th v-if="showRowTableAction" :class="{ sticky_column_action: stickyTable }">
                    &nbsp;
                  </th>

                  <th
                    v-for="(column, column_index) in tableColumns"
                    :key="`${column.id}_${column_index}`"
                    :style="column.styleHeader"
                    :class="addStickyClassHeader(column_index, column.classHeader)"
                  >
                    <span v-if="!column.useTranslate">{{ column.name }}</span>
                    <span v-else>{{ $t(column.name) }}</span>
                  </th>
                </tr>
              </thead>

              <tbody v-if="rows.length > 0">
                <tr
                  v-for="(row, index_row) in rows"
                  :key="`${index_row}_${new Date().getMilliseconds()}`"
                >
                  <td
                    :class="{
                      'td-align-left': true,
                      'text-center': true,
                      'p-1': true,
                      sticky_column_action: stickyTable,
                    }"
                    v-if="showRowTableAction"
                    :style="{ width: '60px !important' }"
                  >
                    <slot name="table-action" :row="row">
                      <div class="dropdown">
                        <button
                          class="btn btn-default"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fas fa-list"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <slot name="table-action-menu" :row="row"></slot>
                        </div>
                      </div>
                    </slot>
                  </td>
                  <td
                    v-for="(cell, index_cell) in row.getCells"
                    :key="index_cell"
                    :style="cell.getColumn.styleCell"
                    :class="addStickyClassCell(index_cell, cell.getColumn.classCell)"
                  >
                    <slot name="table-row" :cell="cell" :row="row">
                      <span>{{ cell.getValue }}</span>
                    </slot>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td :colspan="tableColumns.length + 1" class="text-center">
                    {{ emptyRowsMessage }}
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="showFooterTable">
                <tr>
                  <td
                    v-for="(column, column_index) in tableColumns"
                    :key="`${column_index}_${new Date().getMilliseconds()}_foot_column`"
                  >
                    <slot name="table-tfoot-row" :column="column"> </slot>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="row">
            <div class="col">
              <slot name="description"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Column, Cell, Row, VUE_DATA_TABLE_NEED_DATA_UPDATE } from "./index";
import SkSpinner from "@/components/SkSpinner";

export default {
  name: "VueDataTable",
  components: {
    SkSpinner,
  },
  props: {
    tableColumns: {
      type: Array,
      validator: function (columns) {
        for (let column of columns) {
          if (!(column instanceof Column)) {
            console.error("tableColumns should include only Column instance");
            return false;
          }
        }
        return true;
      },
      required: true,
    },
    tableRows: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    emptyRowsMessage: {
      type: String,
      default: "The table does not contain data",
    },
    showFooterTable: {
      type: Boolean,
      default: true,
    },
    showRowTableAction: {
      type: Boolean,
      default: true,
    },
    showRefreshButton: {
      type: Boolean,
      default: true,
    },
    stickyTable: {
      type: Boolean,
      default: false,
    },
    classTable: {
      default() {
        return ["table", "table-striped"];
      },
    },
  },
  mounted() {
    // // https://stackoverflow.com/questions/76079028/bootstrap-sticky-column-in-table-z-index-issue
    // const observer = new MutationObserver((entries) =>
    //   entries.forEach(({ target }) => {
    //     target.closest(":first-child:is(th, td)").style.zIndex = target.classList.contains("show")
    //       ? "2"
    //       : "";
    //   })
    // );
    // document.querySelectorAll(".dropdown").forEach((dropdown) => {
    //   const thTdFirst = dropdown.closest(":first-child:is(th, td)");
    //   if (thTdFirst) {
    //     observer.observe(dropdown, { attributeFilter: ["class"] });
    //   }
    // });
  },
  data() {
    return {
      /** @param {Array.<Row>} rows */
      rows: [],
      isPreparing: false,
    };
  },
  computed: {
    showSpinner() {
      return this.isLoading || this.isPreparing;
    },
  },
  watch: {
    tableRows: {
      handler() {
        this.onPrepareData();
      },
      immediate: true,
    },
    tableColumns: {
      handler() {
        this.onPrepareData();
      },
    },
  },

  methods: {
    refreshButtonClicked() {
      this.$emit(VUE_DATA_TABLE_NEED_DATA_UPDATE);
    },
    onPrepareData() {
      this.isPreparing = true;

      let data = [];
      for (const row of this.tableRows) {
        let tableRow = new Row({ raw: row });
        /** @param {Column} column */
        for (const column of this.tableColumns) {
          tableRow.addCell(new Cell({ column, value: row }));
        }
        data.push(tableRow);
      }

      this.rows = data;
      this.isPreparing = false;
    },
    addStickyClassCell(index, classes) {
      if (this.stickyTable) {
        if (index == 0) {
          if (Array.isArray(classes)) {
            return [...classes, "sticky_first_column"];
          } else if (classes instanceof Object) {
            return Object.assign(classes, { sticky_first_column: true });
          }
        }
      }
      return classes;
    },
    addStickyClassHeader(index, classes) {
      if (this.stickyTable) {
        if (index == 0) {
          if (Array.isArray(classes)) {
            return [...classes, "sticky_header_first_column"];
          } else if (classes instanceof Object) {
            return Object.assign(classes, { sticky_header_first_column: true });
          }
        } else {
          if (Array.isArray(classes)) {
            return [...classes, "sticky_header_action"];
          } else if (classes instanceof Object) {
            return Object.assign(classes, { sticky_header_action: true });
          }
        }
      }
      return classes;
    },
  },
};
</script>

<style lang="scss">
.btn-default {
  background-color: #f8f9fa;
  border-color: #ddd !important;
  color: #444;
  border-radius: 5px !important;
}

.table {
  border-collapse: collapse;
  & > thead > tr:first-child {
    border-bottom: 2px solid $color-primary;
    border-top: 2px solid $color-primary;
  }
  & > thead > tr > th {
    border-bottom: 2px solid $color-primary;
    border-top: 2px solid $color-primary;
  }

  & > thead > tr > th:first-child,
  & > thead > tr > th:last-child {
    border-right: none;
  }

  & > tbody > tr > td {
    border-right: 1px solid $color-gray-3;
  }

  & > tbody > tr > td:first-child,
  & > tbody > tr > td:last-child {
    border-right: none;
  }
}

.table-striped {
  & tbody tr:nth-of-type(#{$table-striped-order}) {
    background-color: $color-gray-3 !important;
  }
}

.sticky_header_action {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
}

.sticky_header_first_column {
  position: sticky;
  left: 60px !important;
  z-index: 2;
  background: white;
}

.sticky_column_action {
  position: sticky;
  left: 0;
  z-index: 1;
  background: white;
}

.sticky_first_column {
  position: sticky;
  left: 60px !important;
  z-index: 2;
  background: white;
}

:nth-child(1):is(th, td):has(.dropdown.show),
:nth-child(2):is(th, td):has(.dropdown.show) {
  z-index: 3;
}
</style>
