export default class Row {
  /**
   *
   * @param {Array.<Cell>} cells
   * @param raw
   */
  constructor({ cells = [], raw = {} }) {
    this.cells = cells;
    this.raw = raw;
  }

  addCell(cell) {
    this.cells.push(cell);
  }

  get getCells() {
    return this.cells;
  }

  get getRaw() {
    return this.raw;
  }
}
